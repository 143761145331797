import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import ActionModalDefault from '@myaccount/modals/default';
import DialogChildren from './saveSearchDialogChildren';
import defaults from './FabBtnSaveSearchDefaults';

const EditSaveSearch = withSubtheme((props) => {
    const {
        maxDialogW,
        modalTitle,
        openEditForm,
        setOpenEditForm,
        data
    } = props;

    let savedParams = { ...(props?.searchParams || {}), id:data.id, applicant_id:data.applicant_id, is_crm:data.is_crm}

    const [open, setOpen] = React.useState(openEditForm);
    const handleClickOpen = () => {
        setOpen(true);
        setOpenEditForm(true)
    };
    const handleClose = (value) => {
        value.preventDefault();
        setOpen(false);
        setOpenEditForm(false)
    };

    return(
        <>
            <ActionModalDefault
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                children={<DialogChildren handleClose={handleClose} searchParams={savedParams} data={data} />}
                title={modalTitle ? modalTitle : "Register your property preference"}
                maxDialogWidth={maxDialogW}
            />
        </>
    )
}, 'fabBtn', defaults)

export default EditSaveSearch
