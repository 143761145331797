import React from 'react';
import { isEmpty } from 'lodash';
import { withSubtheme } from '@starberryComponentsMui';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { MYACCOUNT_MYPROPERTY_URLS, MYACCOUNT_LIGHT } from "@myaccountUrls";
import { setValuesToUrl } from '@myaccount/helper/urls';
import { getPropertyText, getCurrentTab, getTabLists } from '@myaccount/helper';

import PageTitle from '@myaccount/pageTitle';
import PropertyResultComponent from '@myaccount/property/results';
import PropertySavedSearches from '@myaccount/property/savedSearch';
import SavedProperties from '@myaccount/property/savedProperties';
import ArchivedProperties from '@myaccount/property/archivedProperties';
import { UserObjectStoreProvider, useMyaccountState, useAuthState, PropertyActionStoreProvider, usePropertyActionState } from "@myaccountServices"
// import SwipeFiltersMobile from '@myaccount/customSidebarDrawer/swipeEdge';
// import MenuListTabs from '@myaccount/iconMenuList/menuListIconsTabs';
import ModuleRender from "@myaccount/modules/moduleRender";
import PropertyPreferenceIndex from '../user/propertyPreference';
import MyPropertyResultsList from "./my-property-results-list"

const PropertyPreferencesFormSidebar = React.memo(() => {
    const { state, services } = useAuthState();
    return (
        <Grid container spacing={8}>
            <Grid item xs={12} lg={12}>
                <PropertyPreferenceIndex state={state} services={services} />
            </Grid>
        </Grid>
    )
});

const defaultTabItems = ['all-properties', 'selling', 'letting', 'renting', 'buying', 'wishlist', 'saved-search', 'property-preference', 'archived'];
const defaultTabItem = 'all-properties';

const MyAccountMyProperty = withSubtheme((props) => {
    const { state, services } = useMyaccountState();
    const { services:authServices } = useAuthState();
    const { theme } = props;
    const staticModules = theme.getProp("staticModules");

    const tabItems = props?.tab?.items || defaultTabItems;
    const defaultItem = props?.tab?.default || defaultTabItem;

    let currentTab = getCurrentTab(defaultItem, tabItems)

    const getData = () => {
        if (!MYACCOUNT_LIGHT)
            services.getMypropertiesData()
    }

    const reloadDataFun = () => {
        getData()
    }

    React.useEffect(() => {
        getData()
        authServices.updateState({pageTitle:`My Property`, pageRightCustomComponent: null, pageRightDrawer: null, pageLeftIcon: false});
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    let propData = [],
        buying = [],
        renting = [],
        selling = [],
        letting = [];

    if (state.mypropertiesData) {

        propData = state?.mypropertiesData.buying;
        Object.entries(propData).map(([key, value]) => {
            let propertyDetail = value?.property_detail || {}
            if (!value?.property_detail)
                return;
            let link = setValuesToUrl(MYACCOUNT_MYPROPERTY_URLS, 'buying', propertyDetail?.crm_id)
            buying.push({
                ...propertyDetail,
                link,
                show_hide_button: value?.show_hide_button || false,
                reloadDataFun,
            });
        })

        propData = state?.mypropertiesData.selling;
        Object.entries(propData).map(([key, value]) => {
            let propertyDetail = value?.property_detail || {}
            if (!value?.property_detail)
                return;
            let link = setValuesToUrl(MYACCOUNT_MYPROPERTY_URLS, 'selling', propertyDetail?.crm_id)
            selling.push({
                ...propertyDetail,
                link:link,
                offerText: getPropertyText(value),
                show_hide_button: value?.show_hide_button || false,
                reloadDataFun: reloadDataFun,
            });
        })

        propData = state?.mypropertiesData.letting;
        Object.entries(propData).map(([key, value]) => {
            let propertyDetail = value?.property_detail || {}
            if (!value?.property_detail)
                return;
            let link = setValuesToUrl(MYACCOUNT_MYPROPERTY_URLS, 'letting', propertyDetail?.crm_id)
            letting.push({
                ...propertyDetail,
                link:link,
                offerText: getPropertyText(value),
                show_hide_button: value?.show_hide_button || false,
                reloadDataFun: reloadDataFun,
            });
        })

        propData = state?.mypropertiesData.renting;
        Object.entries(propData).map(([key, value]) => {
            let propertyDetail = value?.property_detail || {}
            if (!value?.property_detail)
                return;
            let link = setValuesToUrl(MYACCOUNT_MYPROPERTY_URLS, 'renting', propertyDetail?.crm_id)
            renting.push({
                ...propertyDetail,
                link:link,
                show_hide_button: value?.show_hide_button || false,
                reloadDataFun: reloadDataFun,
            });
        })

        propData = [...selling, ...letting, ...renting, ...buying];

    }

    const availableTabs = React.useMemo(() => {
        const result = {};

        if (!isEmpty(state?.mypropertiesData)) {
            result["all-properties"] = {
                "id": "all-properties",
                "name": "All Properties",
                "content": <PropertyActionStoreProvider><PropertyResultComponent properties={propData} /></PropertyActionStoreProvider>
            }
        }

        if (selling.length) {
            result['selling'] = {
                "id": "selling",
                "name": "Selling",
                "content": <PropertyActionStoreProvider><PropertyResultComponent properties={selling} /></PropertyActionStoreProvider>
            }
        }

        if (letting.length) {
            result['letting'] = {
                "id": "letting",
                "name": "Letting",
                "content": <PropertyActionStoreProvider><PropertyResultComponent properties={letting} /></PropertyActionStoreProvider>
            }
        }

        if (renting.length) {
            result['renting'] = {
                "id": "renting",
                "name": "Renting",
                "content": <PropertyActionStoreProvider><PropertyResultComponent properties={renting} /></PropertyActionStoreProvider>
            }
        }

        if (buying.length) {
            result['buying'] = {
                "id": "buying",
                "name": "Buying",
                "content": <PropertyActionStoreProvider><PropertyResultComponent properties={buying} /></PropertyActionStoreProvider>
            }
        }

        result['wishlist'] = {
            "id": "wishlist",
            "name": "Wishlist",
            "content": <UserObjectStoreProvider><SavedProperties relatedQuery={props?.savedPropertiesQuery} /></UserObjectStoreProvider>
        }
        result['saved-search'] = {
            "id": "saved-search",
            "name": "Saved Searches",
            "content": <UserObjectStoreProvider><PropertySavedSearches /></UserObjectStoreProvider>,
        }

        result['property-preference'] = {
            "id": "property-preference",
            "name": "Property Preference",
            "content": <PropertyPreferencesFormSidebar />,
        }

        result['archived'] = {
            "id": "archived",
            "name": "Archived",
            "content": <PropertyActionStoreProvider><ArchivedProperties reloadDataFun={reloadDataFun} listType='unarchived' /></PropertyActionStoreProvider>,
        }

        return result;

    }, [
        state?.mypropertiesData,
        propData,
        selling,
        letting,
        renting,
        buying,
        props?.savedPropertiesQuery,
    ]);

    const data = getTabLists(availableTabs, tabItems)

    // Controlling the tabs here so the filter for tabs works in mobile

    return (
        <Container maxWidth="xl">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <PageTitle theme={theme} component="h1" variant="h3">My Property</PageTitle>
                    <MyPropertyResultsList
                        theme={theme}
                        data={data}
                        currentTab={currentTab}
                        tabItems={tabItems}
                        message={state.message}
                        myPropertiesData={state.mypropertiesData}
                        loading={state.loading}
                        error={state.error}
                    />
                    {/* <SwipeFiltersMobile>
                        <MenuListTabs
                            title="Filters"
                            data={data}
                            manageValue={value}
                            manageHandleChange={handleChange}
                        />
                    </SwipeFiltersMobile> */}
                </Grid>
                {staticModules && staticModules.length &&
                    <Grid item xs={12}>
                        <ModuleRender staticModules={staticModules} />
                    </Grid>
                }
            </Grid>
        </Container>
    );
}, 'myPropertyPage')

export default React.memo(MyAccountMyProperty);
