import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';

// Icons
import DeleteIcon from '@mui/icons-material/Delete';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import EditIcon from '@mui/icons-material/Edit';

import defaults from './defaultsActions';

const SavedSearchesActions = withSubtheme((props) => {
    const {
        className,

        id,
        state,
        is_crm,
        params,
        menuId,
        anchorEl,
        isMenuOpen,
        handleMenuClose,
        handleDelete,
        handleEdit,
        handleResuleLink,
        handleState,
        // dropdownActions,
        dropdownActionsIcon,
        showResultLink,
    } = props;

    return(
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            classes={{
                paper: className
            }}
        >
            {(showResultLink && !is_crm) && (
                <MenuItem classes={{root: "globalDropdownActions-dropdownActions"}} onClick={() => handleResuleLink(params)}>
                    <ListItemIcon classes={{root: "globalDropdownActions-dropdownActionsIcon"}}>
                        <ViewHeadlineIcon {...dropdownActionsIcon} />
                    </ListItemIcon>
                    <Typography variant="inherit">View Results</Typography>
                </MenuItem>
            )}
            { true && (
                <MenuItem classes={{root: "globalDropdownActions-dropdownActions"}} onClick={() => { handleEdit(id)}}>
                    <ListItemIcon classes={{root: "globalDropdownActions-dropdownActionsIcon"}}>
                        <EditIcon {...dropdownActionsIcon} />
                    </ListItemIcon>
                    <Typography variant="inherit">Edit</Typography>
                </MenuItem>
            )}
            { !is_crm && (
                <MenuItem classes={{root: "globalDropdownActions-dropdownActions"}} onClick={() => { handleDelete(id)}}>
                    <ListItemIcon classes={{root: "globalDropdownActions-dropdownActionsIcon"}}>
                        <DeleteIcon {...dropdownActionsIcon} />
                    </ListItemIcon>
                    <Typography variant="inherit">Delete Search</Typography>
                </MenuItem>
            )}
            { state && (
                <MenuItem classes={{root: "globalDropdownActions-dropdownActions"}} onClick={() => { handleState(id, !state)}}>
                    <ListItemIcon classes={{root: "globalDropdownActions-dropdownActionsIcon"}}>
                        <NotificationsOffIcon {...dropdownActionsIcon} />
                    </ListItemIcon>
                    <Typography variant="inherit">Stop Alerts</Typography>
                </MenuItem>
            )}
            { !state && (
                <MenuItem classes={{root: "globalDropdownActions-dropdownActions"}} onClick={() => { handleState(id, !state)}}>
                    <ListItemIcon classes={{root: "globalDropdownActions-dropdownActionsIcon"}}>
                        <NotificationsIcon {...dropdownActionsIcon} />
                    </ListItemIcon>
                    <Typography variant="inherit">Start Alerts</Typography>
                </MenuItem>
            )}
        </Menu>
    )
}, 'globalDropdownActions', defaults)

export default SavedSearchesActions;
