export default {
    "props": {
        "containerProps": {
            "spacing": 1,
        },
        "itemProps": {
            "item": true,
            "xs": 12,
        },
        "itemTitleProp": {
            "component": "h3",
        },
        "itemSubtitleProp": {
            "variant": "body1",
        },
        "itemLinkLabelProp": {

        },
    },
    "sx": (theme) => ({
        "& .imageBox-link": {
            "position": "relative",
            "display": "flex",
            "flexBasis": "100%",
            "flexDirection": "column",
            "alignItems": "center",
            "justifyContent": "center",
            "textDecoration": "none",
            "color": theme.palette.text.primary,
            "background": "#fff",
            "boxShadow": "0px 0px 12px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 20%)",
            "borderRadius": theme.shape.borderRadius,
            "&:after": {
                "content": "''",
                "position": "absolute",
                "bottom": 0,
                "left": 0,
                "width": "100%",
                "height": "5px",
                "background": theme.palette.primary.main,
                "borderBottomLeftRadius": theme.shape.borderRadius,
                "borderBottomRightRadius": theme.shape.borderRadius,
                "opacity": 1,
                "zIndex": 2,
                "transition": "background 0.4s cubic-bezier(.17,.67,.83,.67)",
            },
            "& .imageBox-content": {
                "display": "block",
                "position": "relative",
                "textAlign": "center",
                "width": "100%",
                "height": "100%",
                // "padding": theme.spacing(3),
                "zIndex": 5,
                // [theme.breakpoints.up('md')]: {
                //     "padding": theme.spacing(5),
                // },
                "& .imageBox-icon": {
                    "width": "100%",
                    "height": "auto",
                    // "margin": `0 auto ${theme.spacing(1)} auto`,
                    // [theme.breakpoints.up('md')]: {
                    //     "margin": `0 auto ${theme.spacing(2)} auto`,
                    // },
                },
                "& .imageBox-title": {
                    "fontSize": theme.h6,
                    "lineHeight": theme.h6LineH,
                    "color": theme.palette.primary.main,
                    "marginBottom": theme.spacing(1),
                    [theme.breakpoints.up('md')]: {
                        "fontSize": theme.h5,
                        "lineHeight": theme.h5LineH,
                        "marginBottom": theme.spacing(2),
                    },
                },
                "& .imageBox-subtitle": {
                    "color": theme.palette.text.primary,
                    "fontSize": theme.bodyMedium,
                    "lineHeight": theme.lineHeightBMedium,
                    "marginBottom": theme.spacing(1),
                    [theme.breakpoints.up('md')]: {
                        "marginBottom": theme.spacing(2),
                    },
                },
                "& .imageBox-linkLabel": {
                    "fontSize": theme.bodyMedium,
                    "textTransform": "uppercase",
                    "color": theme.palette.primary.dark,
                    "fontWeight": theme.typography.fontWeightBold,
                },
            },
            "&:hover": {
                "&:after": {
                //     "background": theme.palette.primary.main + "99", // 60% transparency
                    "background": theme.palette.primary.dark,
                    // "transition": "background 0.4s cubic-bezier(.17,.67,.83,.67)",
                },
            },
        },
    }),
}