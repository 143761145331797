import React from 'react';
import PropertySavedSearches from '@myaccount/property/savedSearch';
import { UserObjectStoreProvider } from "@myaccountServices";

const PropertyPreferenceIndex = () => {
    return(
        <UserObjectStoreProvider>
            <PropertySavedSearches
                _queryParams={{ is_crm: true }}
                hideAddMore={true}
                noactionTitle={`There are no property preferences`}
                noactionMessage={` `}
                labelText={`Add Preference`}
                modalTitle={`Register your property preference`}
            />
        </UserObjectStoreProvider>
    )
}

export default PropertyPreferenceIndex;
