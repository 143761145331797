import React from "react";
import { withSubtheme } from '@starberryComponentsMui';
import GlobalForm from "@myaccount/forms/";
import { collectFormValues, validateFormFields } from "@myaccount/forms/utils";
import { useFormState } from "@myaccountServices";
import reportFaultFields from "@myaccount/forms/Forms/valuationStepOne";

const DialogChildren = withSubtheme((props) => {
    const { className } = props

    const { state, services } = useFormState()
    let customFields = [...reportFaultFields];

    const [formFields, setFormFields ] = React.useState([...customFields]);

    const ref = React.useRef(null)
    const formStateType = {}
    const messageDialog = { message:state.message, severity:state.error ? 'error' : 'success'}
    const hideForm = (state.success && !state.error)
    const formData = { submitLabel: "Show Appointments" }

    const onSubmit = event => {
        event.preventDefault()

        const formWithValue = validateFormFields(ref, customFields)
        setFormFields(formWithValue);
        // inputRef
        if (ref.current.checkValidity() !== false) {
            let params = {
                form_name: "valuation",
                // step: step,
                // successMessage: "Valuation Posted Successfully",
                // ...actionParams.formParams,
            }

            const values = collectFormValues(ref, params)
            // services.postForm(values)
            // services.changeFormState({...values, step:'stepTwo'});
            services.changeFormState({"formState": {...values, step:'stepTwo'}, step:'stepTwo'});
        } else {
            // @todo
        }
    }

    return(
        <div className={className}>
            <GlobalForm
                formFields={formFields}
                handleSubmit={onSubmit}
                formStateType={formStateType}
                messageDialog={messageDialog}
                data={formData}
                ref={ref}
                hideForm={hideForm}
            />
        </div>
    )
}, 'dialogChildren')

export default DialogChildren
