import React, { useState } from "react";
import Typography from '@mui/material/Typography';
import { Link as GatsbyLink } from "gatsby";
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import ButtonBase from '@mui/material/ButtonBase';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FsLightbox from 'fslightbox-react';
import ModalPortal from '@myaccount/modals/ModalPortal';

const ImageOrVideo = (props) => {
    const {
        linkUrl,
        videoUrl,
        imgUrl,
        imgAlt,
        lightboxType,
    } = props

    const [lightboxController, setLightboxController] = useState(false);
    function openLightbox() {
        setLightboxController(!lightboxController);
    }

    return(
        <>
            {videoUrl ? // if video link
                <>
                    <ButtonBase
                        className="videoText-link videoText-link-video"
                        style={{
                            "backgroundImage": `url("${imgUrl}")`
                        }}
                        alt={imgAlt}
                        onClick={() => openLightbox()}
                    >
                        <span className="imageBackdrop" />
                        <PlayCircleIcon classes={{root: "videoText-play-icon"}} />
                    </ButtonBase>
                    <ModalPortal>
                        <FsLightbox
                            toggler={lightboxController}
                            sources={[videoUrl]}
                            type={lightboxType}
                        />
                    </ModalPortal>
                </>
            :
                <>
                    {linkUrl ? // else if image has a normal link
                        <GatsbyLink
                            className="videoText-link"
                            to={linkUrl ? linkUrl : "#"}
                            style={{
                                "backgroundImage": `url("${imgUrl}")`
                            }}
                            alt={imgAlt}
                        >
                            <span className="imageBackdrop" />
                        </GatsbyLink>

                        : // else use image without a link

                        <span
                            className="videoText-link"
                            style={{
                                "backgroundImage": `url("${imgUrl}")`
                            }}
                            alt={imgAlt}
                        >
                            <span className="imageBackdrop" />
                        </span>
                    }
                </>
            }
        </>
    )
}

const SelectIcon = (props) => {
    switch (props?.icon) {
        case "PhoneIcon":
            return <PhoneIcon />
        case "EmailIcon":
            return <EmailIcon />
        default:
            break;
    }
}

const RenderBtns = ({btns}) => {
    return(
        <div className="videoText-btn-container">
            {btns.map((btn, i) => {
                const {
                    type,
                    label,
                    url,
                    icon,
                    linkProps,
                } = btn

                switch (type) {
                    case "tel":
                    case "email":
                        return <Link href={url} key={i} {...linkProps} classes={{root: "videoText-btn-link"}}><SelectIcon icon={icon} />{label}</Link>
                    default:
                        return <Link component={GatsbyLink} to={url} {...linkProps} key={i} className="videoText-btn-link"><SelectIcon icon={icon} />{label}</Link>
                }
            })}
        </div>
    )
}

const Item = (props) => {
    const {
        item,
        itemTitleProp,
        itemContentProp
    } = props

    const {
        title,
        text,
        buttons,
        containerProps,
        itemTextContainerProp,
        itemImageContainerProps,
    } = item

    return(
        <Grid container {...containerProps} className="videoText-container">
            <Grid {...itemTextContainerProp} classes={{root: "videoText-content"}}>
                {title && <Typography {...itemTitleProp} classes={{root: "videoText-title"}}>{title}</Typography>}
                {text && <Typography {...itemContentProp} classes={{root: "videoText-text"}}>{text}</Typography>}
                {buttons && buttons.length && <RenderBtns btns={buttons} />}
            </Grid>
            <Grid {...itemImageContainerProps} classes={{root: "videoText-image"}}>
                <ImageOrVideo {...item} />
            </Grid>
        </Grid>
    )
}
export default Item
