import React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import ImageBox from "@myaccount/modules/imageBox";
import IconBox from "@myaccount/modules/iconBox";
import VideoText from "@myaccount/modules/videoText";
import GridContainer from "@myaccount/modules/grid";
import BgIcon from "@myaccount/modules/bgIcon";
import ComponentLayout from '@myaccount/ComponentLayout';
import Grid from "@mui/material/Grid";

import defaults from './defaults';

const LoopModules = ({modules}) => {
    return(
        <>
            {modules.map((module, i) => {
                return(
                    Object.entries(module).map(([key, value]) => {
                        switch (key) {
                            case "2column":
                                return <GridContainer {...value} key={i} />
                            case "imageBox":
                                return <ImageBox {...value} key={i} />
                            case "iconBox":
                                return <IconBox {...value} key={i} />
                            case "videoText":
                                return <VideoText {...value} key={i} />
                            case "bgIcon":
                                return <BgIcon {...value} key={i} />
                            default:
                                break;
                        }
                    })
                )
            })}
        </>
    )
}

const GridRender = withSubtheme((props) => {
    const {
        theme,
        className,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        containerProps,
        leftColumnProps,
        rightColumnProps,
        leftColumn,
        rightColumn,
    } = props

    return(
        <ComponentLayout
            className={className}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <Grid container {...containerProps}>
                {leftColumn &&
                    <Grid {...leftColumnProps}>
                        <LoopModules modules={leftColumn} />
                    </Grid>
                }
                {rightColumn &&
                    <Grid {...rightColumnProps}>
                        <LoopModules modules={rightColumn} />
                    </Grid>
                }
            </Grid>
        </ComponentLayout>
    )
}, "static2column", defaults)

export default GridRender
