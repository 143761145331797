import React from "react";
import { withSubtheme } from '@starberryComponentsMui';
import GlobalForm from "@myaccount/forms/";
import { collectFormValues, validateFormFields } from "@myaccount/forms/utils";
import { useFormState } from "@myaccountServices";
import reportFaultFields from "@myaccount/forms/Forms/valuationStepTwo";
import { trackValuation } from "@myaccount/helper/eventTracking";

const DialogChildren = withSubtheme((props) => {
    const {
        className,
        successMessage = "Valuation Posted Successfully"
    } = props

    const { state, services } = useFormState()
    let customFields = [...reportFaultFields];

    const [formFields, setFormFields ] = React.useState([...customFields]);

    const ref = React.useRef(null)
    const formStateType = {}
    const messageDialog = { message:state.message, severity:state.error ? 'error' : 'success'}
    const hideForm = (state.success && !state.error)
    const formData = { submitLabel: "Book Appointment" }
    let previousFormState = state?.formState || {};
    if (previousFormState.step)
        delete previousFormState.step;

    const onSubmit = event => {
        event.preventDefault()

        const formWithValue = validateFormFields(ref, customFields)
        setFormFields(formWithValue);
        // inputRef
        if (ref.current.checkValidity() !== false) {
            let params = {
                ...previousFormState,
                form_name: "valuation",
                // step: step,
                successMessage,
                // ...actionParams.formParams,
            }

            let values = collectFormValues(ref, params);
            values['trackData'] = () => {
                trackValuation({
                    eventLabel: values.property_type
                })
            }

            // console.log("stepTwo => ", values);
            services.postForm(values)
        } else {
            // @todo
        }
    }

    return(
        <div className={className}>
            <GlobalForm
                formFields={formFields}
                handleSubmit={onSubmit}
                formStateType={formStateType}
                messageDialog={messageDialog}
                data={formData}
                ref={ref}
                hideForm={hideForm}
                customParams={{...previousFormState}}
            />
        </div>
    )
}, 'dialogChildren')

export default DialogChildren
