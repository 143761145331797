import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Link as GatsbyLink } from "gatsby";
import ValuationModule from '@myaccount/simpleModule/valuation';

const DefaultBox = (props) => {
    const {
        item,
        itemTitleProp,
        itemSubtitleProp,
        itemLinkLabelProp,
    } = props

    const {
        title,
        subtitle,
        linkUrl,
        linkLabel,
        imgUrl,
        imgAlt,
        icon,
    } = item

    const BoxIcon = icon;
    const [ show, setShow ] = React.useState(false);

    return(
        <>
            <GatsbyLink
                className="imageBox-link"
                to={linkUrl ? linkUrl : "#"}
                style={{
                    "backgroundImage": imgUrl ? `url("${imgUrl}")` : ""
                }}
                alt={imgAlt ? imgAlt : null}
                onClick={(e) => {e.preventDefault(); setShow(true);}}

            >
                <span className="imageBox-content">
                    {icon && <div className="imageBox-icon"><BoxIcon /></div>}
                    {title && <Typography {...itemTitleProp} classes={{root: "imageBox-title"}}>{title}</Typography>}
                    {subtitle && <Typography {...itemSubtitleProp} classes={{root: "imageBox-subtitle"}}>{subtitle}</Typography>}
                    {linkLabel && <Typography {...itemLinkLabelProp} classes={{root: "imageBox-linkLabel"}}>{linkLabel}</Typography>}
                </span>
            </GatsbyLink>
            {show && (
                <ValuationModule setShow={setShow} />
            )}
        </>
    )
}
export default DefaultBox
