import * as React from 'react';
import DefaultBox from './default';
import BookValuationBox from './bookValuation';

const SwitchBoxType = (props) => {
    const { item } = props

    switch (item?.type) {
        case "external":
            return <DefaultBox {...props} />
        case "bookValuation":
            return <BookValuationBox {...props} />
        default:
            return <DefaultBox {...props} />
    }
}
export default SwitchBoxType