export default {
    "_data": [
        {
            "action": "viewing_created",
            "action_id": "RPT2000078",
            "applicant_id": "RPT200076",
            "applicant_name": "Sanmugam Kathirvel",
            "applicant_type": "buying",
            "createdAt": "2020-12-16T13:54:11.125Z",
            "id": "5fda1183d60bc97fb279035dn",
            "image": "https://app.reapit.net/aac/live/pictures/PRP/20/PRP201297_21.jpg",
            "is_applicant": true,
            "price": 350000,
            "price_qualifier": "askingPrice",
            "property_address": {},
            "property_id": "NGL110189",
            "updatedAt": "2020-12-16T13:54:11.125Z",
            "value": "2020-12-14T12:30:02Z",
        },
        {
            "action": "viewing",
            "action_id": "RPT2000078f",
            "applicant_id": "RPT200076b",
            "applicant_name": "Sanmugam Kathirvel",
            "applicant_type": "buying",
            "createdAt": "2020-12-16T13:54:11.125Z",
            "id": "5fda1183d60bc97fb279035dm",
            "image": "https://app.reapit.net/aac/live/pictures/PRP/20/PRP201297_21.jpg",
            "is_applicant": true,
            "price": 350000,
            "price_qualifier": "askingPrice",
            "property_address": {},
            "property_id": "NGL110189",
            "updatedAt": "2020-12-16T13:54:11.125Z",
            "value": "2020-12-14T12:30:02Z",
        },
        {
            "action": "viewing_created",
            "action_id": "RPT20000785",
            "applicant_id": "RPT200076n",
            "applicant_name": "Sanmugam Kathirvel",
            "applicant_type": "buying",
            "createdAt": "2020-12-16T13:54:11.125Z",
            "id": "5fda1183d60bc97fb279035dh",
            "image": "https://app.reapit.net/aac/live/pictures/PRP/20/PRP201297_21.jpg",
            "is_applicant": true,
            "price": 350000,
            "price_qualifier": "askingPrice",
            "property_address": {},
            "property_id": "NGL110189",
            "updatedAt": "2020-12-16T13:54:11.125Z",
            "value": "2020-12-14T12:30:02Z",
        }
    ]
}