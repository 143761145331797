import React from 'react';
import Stack from "@mui/material/Stack"
import Skeleton from "@mui/material/Skeleton"
import Box from "@mui/material/Box"

const ActionSkeleton = () => (
    <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
    >
        <Stack direction="row" alignItems="center" spacing={4}>
            <Skeleton variant="circular" width={48} height={48} />
            <Stack direction="column" spacing={1}>
                <Skeleton variant="text" width={180} height={24} />
                <Skeleton variant="text" width={110} height={24} />
            </Stack>
        </Stack>
    </Box>
);

export default ActionSkeleton;
