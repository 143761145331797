import * as React from 'react';
import { slice } from "lodash";
import { withSubtheme } from '@starberryComponentsMui';

import ComponentLayout from '@myaccount/ComponentLayout';
import SwitchActions from '@myaccount/actions/switchActions';

import defaults from './defaults';

const ActivitiesContainer = withSubtheme((props) => {
    const {
        className,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        data,
        _data,
        ...rest
    } = props;

    let useData = data ? data : _data || [];

    // Limit to load last 20 records for now
    if (useData.length) {
        useData = slice(useData, 0, 20);
    }

    return(
        <ComponentLayout
            className={className}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            {useData.map(action => {
                return(
                    <>
                        <SwitchActions
                            key={action.id}
                            type={action.action}
                            data={{...action, showActionButton: false}}
                            timesAgo={true}
                            actionType={`activity`}
                            {...rest}
                        />
                    </>
                )
            })}
        </ComponentLayout>
    )
}, 'activityCard', defaults)

export default ActivitiesContainer;
