import React from 'react';
import Stack from "@mui/material/Stack"
import Skeleton from "@mui/material/Skeleton"

const PropertyPreferenceSkeleton = () => (
    <Stack direction="row" spacing={2} alignItems="center">
        <Stack direction="column" spacing={1} sx={{flex: 1}}>
            <Skeleton variant="text" width="80%" height={24} />
            <Skeleton variant="text" width="40%" height={24} />
        </Stack>
        <Skeleton variant="rectangular" width={28} height={15} />
    </Stack>
);

export default PropertyPreferenceSkeleton;
