import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Add as AddIcon, SearchOutlined } from '@mui/icons-material';
import ActionModalDefault from '@myaccount/modals/default';
import DialogChildren from './saveSearchDialogChildren';

import defaults from './FabBtnSaveSearchDefaults';

const FabBtnSaveSearch = withSubtheme((props) => {
    const {
        className,
        maxDialogW,
        fabProps,
        label,
        modalTitle,
        addNew,
        showBoxContainer,
        boxContainerProps,
        boxContainerImageProps,
        boxContainerContentProps,
        boxContainerBtnContainerProps,
        boxContainerBtnProps,
        boxContainerTitleProps,
        boxContainerTextProps,
        containerTitle,
        containerText,
    } = props;

    const labelText = label ? label : "Add Search"

    const [open, setOpen] = React.useState(showBoxContainer ? false : (addNew || false));
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (value) => {
        value.preventDefault();
        setOpen(false);
    };

    return(
        <>
            {showBoxContainer && <Grid classes={{root: "box-container"}} {...boxContainerProps}>
                <Grid {...boxContainerImageProps} classes={{root: "box-image-container"}}>
                    <IconButton classes={{root: "box-image"}} onClick={handleClickOpen}>
                        <SearchOutlined />
                    </IconButton>
                </Grid>
                <Grid classes={{root: "box-content"}} {...boxContainerContentProps}>
                    {containerTitle && <Typography classes={{root: "box-container-title"}} {...boxContainerTitleProps}>{containerTitle}</Typography>}
                    {containerText && <Typography classes={{root: "box-container-text"}}{...boxContainerTextProps}>{containerText}</Typography>}
                </Grid>
                <Grid {...boxContainerBtnContainerProps} classes={{root: "box-btn-container"}}>
                    <Button {...boxContainerBtnProps} onClick={handleClickOpen}>{labelText}</Button>
                </Grid>
            </Grid>
            }

            {!showBoxContainer &&
                <Fab {...fabProps} classes={{root: className}} aria-label="add" onClick={handleClickOpen}>
                    <AddIcon sx={{ mr: 1 }} />
                    {labelText}
                </Fab>
            }
            <ActionModalDefault
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                children={<DialogChildren handleClose={handleClose} searchParams={props?.searchParams} defaultArea={props.defaultArea} defaultSearchType={props.defaultSearchType} />}
                title={modalTitle ? modalTitle : "pass modalTitle to change"}
                maxDialogWidth={maxDialogW}
            />
        </>
    )
}, 'fabBtn', defaults)

export default FabBtnSaveSearch
