import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Link as GatsbyLink } from "gatsby";

const DefaultBox = (props) => {
    const {
        item,
        itemTitleProp,
        itemSubtitleProp,
        itemLinkLabelProp,
    } = props

    const {
        title,
        subtitle,
        linkUrl,
        linkLabel,
        imgUrl,
        imgAlt,
        icon,
        target,
    } = item

    const BoxIcon = icon;

    return(
        <GatsbyLink
            className="imageBox-link"
            to={linkUrl ? linkUrl : "#"}
            style={{
                "backgroundImage": imgUrl ? `url("${imgUrl}")` : ""
            }}
            alt={imgAlt ? imgAlt : null}
            target={`${target || '_blank'}`}
        >
            <span className="imageBox-content">
                {icon && <div className="imageBox-icon"><BoxIcon /></div>}
                {title && <Typography {...itemTitleProp} classes={{root: "imageBox-title"}}>{title}</Typography>}
                {subtitle && <Typography {...itemSubtitleProp} classes={{root: "imageBox-subtitle"}}>{subtitle}</Typography>}
                {linkLabel && <Typography {...itemLinkLabelProp} classes={{root: "imageBox-linkLabel"}}>{linkLabel}</Typography>}
            </span>
        </GatsbyLink>
    )
}
export default DefaultBox
