import React from 'react';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import range from 'lodash/range';

const RecentActivitiesSkeleton = () => {

    return (
      <Stack direction="column" spacing={3} sx={{my: 2}}>
          {range(0, 3).map(index => (
              <Box
                  key={index}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
              >
                  <Stack direction="row" alignItems="center" spacing={4}>
                      <Skeleton variant="circular" width={48} height={48} />
                      <Stack direction="column" spacing={1}>
                          <Skeleton
                              sx={(theme) => ({
                                  [theme.breakpoints.down('sm')]: {
                                      display: 'none',
                                  }
                              })}
                              variant="text"
                              width={400}
                              height={24}
                          />
                          <Skeleton
                              sx={(theme) => ({
                                  [theme.breakpoints.up('sm')]: {
                                      display: 'none',
                                  }
                              })}
                              variant="text"
                              width={250}
                              height={24}
                          />
                          <Skeleton variant="text" width={110} height={24} />
                      </Stack>
                  </Stack>
              </Box>
          ))}
      </Stack>
    );
};

export default RecentActivitiesSkeleton;
