import React from "react";
import { withSubtheme } from '@starberryComponentsMui';
import Typography from '@mui/material/Typography';
import GlobalForm from "@myaccount/forms/";
import { collectFormValues, validateFormFields, isRequiredFieldHasValues } from "@myaccount/forms/utils";
import { useFormState } from "@myaccountServices";
import reportFaultFields from "@myaccount/forms/Forms/bookViewingCustom";
import { trackValuation } from "@myaccount/helper/eventTracking";

const DialogChildren = withSubtheme((props) => {
    const { className, actionCardDialogChildrenStyles } = props

    const { state, services } = useFormState()

    const introMessage = state?.introMessage || '';

    let customFields = [...reportFaultFields];

    const [formFields, setFormFields ] = React.useState([...customFields]);

    const ref = React.useRef(null)
    const formStateType = {}
    const messageDialog = { message:state.message, severity:state.error ? 'error' : 'success'}
    const hideForm = (state.success && !state.error)
    const formData = { submitLabel: "Submit" }
    let previousFormState = state?.formState || {};
    if (previousFormState.step)
        delete previousFormState.step;

    const onSubmit = event => {
        event.preventDefault()


        const formWithValue = validateFormFields(ref, customFields)
        setFormFields(formWithValue);
        // inputRef
        if (ref.current.checkValidity() !== false && isRequiredFieldHasValues(formWithValue)) {
            const params = {
                ...previousFormState,
                form_name: "valuation",
                // step: step,
                successMessage: "Valuation Request Posted Successfully",
                // ...actionParams.formParams,
                trackData: () => {
                    trackValuation()
                }
            }
            const values = collectFormValues(ref, params)
            services.postForm(values)
        } else {
            // @todo
        }
    }

    return(
        <div className={className}>
            {introMessage && (
                <Typography  {...actionCardDialogChildrenStyles.actionChildrenTitleProps} classes={{root: "actionCardDialogChildren-subTitle"}}>
                    {introMessage}
                </Typography>
            )}
            <GlobalForm
                formFields={formFields}
                handleSubmit={onSubmit}
                formStateType={formStateType}
                formState={state}
                messageDialog={messageDialog}
                data={formData}
                ref={ref}
                hideForm={hideForm}
            />
        </div>
    )
}, 'dialogChildren')

export default DialogChildren
