import React from 'react';
import range from 'lodash/range';
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import DesktopPropertyCardSkeleton
    from "../../../StarberryComponentsMui/components/Skeleton/DesktopPropertyCardSkeleton"
import MobilePropertyCardSkeleton from "../../../StarberryComponentsMui/components/Skeleton/MobilePropertyCardSkeleton"
import TabSkeleton from "../../../StarberryComponentsMui/components/Skeleton/TabSkeleton"
import TabContainerSkeleton from "../../../StarberryComponentsMui/components/Skeleton/TabContainerSkeleton"

const MyPropertiesResultsListSkeleton = () => {

    return (
        <Stack direction="column" spacing={2} my={2}>
            <Stack direction="column" spacing={1}>
                <TabContainerSkeleton>
                    {range(0, 4).map(index => (
                        <TabSkeleton key={index} />
                    ))}
                </TabContainerSkeleton>
            </Stack>
            <Box>
                <Grid
                    sx={theme => ({
                        [theme.breakpoints.down('sm')]: {
                          display: 'none',
                        },
                    })}
                    container
                    spacing={4}
                >
                    {range(0, 6).map(index => (
                        <Grid key={index} item sm={6} md={6} lg={4}>
                            <DesktopPropertyCardSkeleton />
                        </Grid>
                    ))}
                </Grid>
                <Grid
                    sx={theme => ({
                        [theme.breakpoints.up('sm')]: {
                            display: 'none',
                        },
                    })}
                    container
                    spacing={2}
                >
                    {range(0, 6).map(index => (
                        <Grid key={index} item xs={12}>
                            <MobilePropertyCardSkeleton />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Stack>
    )
};

export default MyPropertiesResultsListSkeleton;
