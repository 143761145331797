import React from "react"
import Stack from "@mui/material/Stack"
import Skeleton from "@mui/material/Skeleton"
import AutoHeightSkeleton from "./AutoHeightSkeleton"

const MobilePropertyCardSkeleton = () => (
    <Stack direction="row" spacing={2}>
        <AutoHeightSkeleton
            variant="rectangular"
            width="40%"
            aspectRatio={0.5625}
        />
        <Stack
            sx={{flex: 1}}
            direction="column"
            spacing={1}
            justifyContent="center"
        >
            <Skeleton
                variant="text"
                width={180}
                height={25}
            />
            <Skeleton
                variant="text"
                width={200}
                height={20}
            />
        </Stack>
    </Stack>
);

export default MobilePropertyCardSkeleton;
