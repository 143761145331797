import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import PropertyGrid from '@components/PropertyGrid';
import ComponentLayout from '@myaccount/ComponentLayout';
import NoActions from "@myaccount/progress/NoActions";

import defaults from './defaults.js';

const PropertyResultComponent = withSubtheme((props) => {
    const {
        className,
        properties,
        listType,
        reloadDataFun,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps
    } = props

    return(
        <ComponentLayout
            className={className}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <>
                {properties.length ? (
                    <PropertyGrid
                        properties={properties}
                        listType={listType}
                        reloadDataFun={reloadDataFun}
                    />
                ) : (
                    <NoActions
                        title={"No Properties are available"}
                    />
                )}
            </>
        </ComponentLayout>
    )
}, 'propertyResultComponent', defaults)

export default PropertyResultComponent
