import * as React from 'react';
import clsx from 'clsx';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import PageviewIcon from '@mui/icons-material/Pageview';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import PropertyPreferences from '@myaccount/user/propertyPreference/dialog';
import EditSaveSearch from '@myaccount/fabBtn/saveSearch/editSaveSearch';
import { doRedirect } from "@myaccount/utils"
import { trackSaveSearch } from "@myaccount/helper/eventTracking";

import SavedSearchesActions from './actions';

const SavedParams = (props) => {
    const {
        content,
        cardContent
    } = props

    const {
        cardContentItem,
        cardContentItemName,
        cardContentItemParams,
        ...rest
    } = cardContent

    const {
        cardContentItemContainer,
        cardContentItemItem,
        ...restCardContentItem
    } = cardContentItem

    return (
        <Grid {...rest} classes={{root: "savedSearchResults-cardContent"}}>
            {content && content.map((value) => (
                <Grid key={value.id} {...restCardContentItem}>
                    <Grid {...cardContentItemContainer}>
                        <Grid {...cardContentItemItem}>
                            <Typography classes={{root: "cardContentItemName"}} {...cardContentItemName}>{value.title}</Typography>
                        </Grid>
                        <Grid {...cardContentItemItem}>
                            <Typography classes={{root: "cardContentItemParams"}} {...cardContentItemParams}>{value.param}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    )
}

export default function Action(props) {
    const {
        data,
        card,
        classesCard,
        cardAction,
        showActionButton,
        cardActionButton,
        cardContent,
        services,
        resultLinkFun,
        ...rest
    } = props;

    const { key, title, content, state, id, params, is_crm } = data;

    // Dropdown actions
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openEditForm, setOpenEditForm] = React.useState(false);
    const isMenuOpen = Boolean(anchorEl);

    const showResultLink = typeof resultLinkFun === 'function' ? true : false;

    const handleActionsOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleResuleLink = ( params ) => {
        trackSaveSearch({
            eventLabel: "View Result"
        })
        setAnchorEl(null);
        let url = resultLinkFun(params);
        if (url)
            doRedirect(url);
    };

    const handleDelete = (id) => {
        trackSaveSearch({
            eventLabel: "Delete"
        })
        setAnchorEl(null);
        services.deleteUserobject({id:id})
    };

    const handleEdit = (id) => {
        setAnchorEl(null);
        setOpenEditForm(true)
    };

    const handleState = (id, state) => {
        trackSaveSearch({
            eventLabel: "Stop/Start Alert"
        })
        setAnchorEl(null);
        services.updateUserobject({id:id, state:state})
    };

    const menuId = `savedSearchAction${key}`;

    return(
        <Card
            className={clsx("savedSearchResults-card", {"disabled": !state})}
            {...card}
            {...rest}
        >
            <CardHeader
                avatar={showActionButton &&
                    <IconButton aria-label="pending actions" size="large" classes={{root: "savedSearchResults-cardActionButton"}} {...cardActionButton}>
                        <PageviewIcon fontSize="inherit" />
                    </IconButton>
                }
                action={
                    <IconButton aria-label="more settings" {...cardAction} onClick={handleActionsOpen}>
                        <MoreHorizIcon fontSize="inherit" />
                    </IconButton>
                }
                title={title}
                titleTypographyProps={{"variant": "h3", "component": "h3"}}
                subheaderTypographyProps={{"variant": "caption"}}
                subheader={
                    <SavedParams
                        content={content}
                        cardContent={cardContent}
                    />
                }
                classes={{
                    root: "savedSearchResults-cardHeader",
                    avatar: "savedSearchResults-avatar",
                    title: "savedSearchResults-title",
                    subheader: "savedSearchResults-subheader",
                    action: "savedSearchResults-action"
                }}
            />
                <SavedSearchesActions
                    id={id}
                    state={state}
                    params={params}
                    menuId={menuId}
                    anchorEl={anchorEl}
                    isMenuOpen={isMenuOpen}
                    handleMenuClose={handleMenuClose}
                    handleDelete={handleDelete}
                    handleResuleLink={handleResuleLink}
                    showResultLink={showResultLink}
                    handleState={handleState}
                    handleEdit={handleEdit}
                    is_crm={is_crm}
                />
                {openEditForm && (
                    <EditSaveSearch
                        openEditForm={openEditForm} setOpenEditForm={setOpenEditForm} data={{...data}}
                    />
                )}
        </Card>
    )
}
