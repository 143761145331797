export default {
    "props": {
        "containerProps": {
            "spacing": 1,
        },
        "itemProps": {
            "item": true,
            "xs": 12,
        },
        "itemTitleProp": {
            "component": "h3",
            // "variant": "h5",
            // "color": "#fff",
        },
        "itemContentProp": {
            // "variant": "body1",
            // "color": "#fff",
        },
    },
    "sx": (theme) => ({
        "& .videoText-container": {
            "& .videoText-content": {
                "padding": theme.spacing(3),
                "background": theme.palette.grey.grey4,
                [theme.breakpoints.up('md')]: {
                    "padding": theme.spacing(4),
                },
                [theme.breakpoints.up('lg')]: {
                    "padding": theme.spacing(6),
                },
                "& .videoText-title": {
                    "fontWeight": theme.typography.fontWeightBold,
                    "fontSize": theme.bodySmall,
                    "lineHeight": theme.lineHeightBSmall,
                    "marginBottom": theme.spacing(1),
                    [theme.breakpoints.up('md')]: {
                        "fontSize": theme.h5,
                        "lineHeight": theme.h5LineH,
                        "marginBottom": theme.spacing(2),
                    },
                    [theme.breakpoints.up('lg')]: {
                        "fontSize": theme.h4,
                        "lineHeight": theme.h4LineH,
                        "marginBottom": theme.spacing(2),
                    },
                },
                "& .videoText-text": {
                    "fontSize": theme.bodyXSmall,
                    "lineHeight": theme.lineHeightBXSmall,
                    "marginBottom": theme.spacing(1),
                    [theme.breakpoints.up('md')]: {
                        "fontSize": theme.bodySmall,
                        "lineHeight": theme.lineHeightBSmall,
                        "marginBottom": theme.spacing(2),
                    },
                    [theme.breakpoints.up('lg')]: {
                        "fontSize": theme.bodyMedium,
                        "lineHeight": theme.lineHeightBMedium,
                        "marginBottom": theme.spacing(3),
                    },
                },
                "& .videoText-btn-container": {
                    "display": "flex",
                    "flexDirection": "column",
                    "fontSize": theme.bodyXSmall,
                    "lineHeight": theme.lineHeightBXSmall,
                    "fontWeight": theme.typography.fontWeightBold,
                    [theme.breakpoints.up('md')]: {
                        "fontSize": theme.bodySmall,
                        "lineHeight": theme.lineHeightBSmall,
                    },
                    [theme.breakpoints.up('lg')]: {
                        "flexDirection": "row",
                        "fontSize": theme.bodyMedium,
                        "lineHeight": theme.lineHeightBMedium,
                    },
                    "& .videoText-btn-link": {
                        "display": "flex",
                        "flexDirection": "row",
                        "alignItems": "center",
                        [theme.breakpoints.up('lg')]: {
                            "marginRight": theme.spacing(3),
                        },
                        "&:last-child": {
                            "marginRight": 0,
                        },
                        "&:hover": {
                            "color": theme.palette.primary.main,
                        },
                        "& svg": {
                            "marginRight": "11px",
                            "marginTop": "-2px",
                        },
                    },
                },
            },
            "& .videoText-image": {
                "background": theme.palette.grey.grey4,
                "& .videoText-link": {
                    "position": "relative",
                    "display": "block",
                    "backgroundRepeat": "no-repeat",
                    "backgroundSize": "cover",
                    "backgroundPosition": "center",
                    "minHeight": "220px",
                    [theme.breakpoints.up('md')]: {
                        "height": "100%",
                        // "marginLeft": theme.spacing(5), // enable this if the order of image - text is reverse
                        "marginRight": theme.spacing(5),
                    },
                    "&.videoText-link-video": {
                        "width": "100%",
                        [theme.breakpoints.up('md')]: {
                            "width": `calc(100% - ${theme.spacing(5)})`,
                        },
                    },
                    "& .videoText-play-icon": {
                        "position": "relative",
                        "fontSize": "60px",
                        "color": theme.palette.secondary.main,
                        "zIndex": 5,
                    },
                    "& .imageBackdrop": {
                        "position": 'absolute',
                        "left": 0,
                        "right": 0,
                        "top": 0,
                        "bottom": 0,
                        "background": "linear-gradient(transparent, rgba(0,0,0,0.7))",
                        // "backgroundColor": theme.palette.common.black,
                        "zIndex": 2,
                        // "opacity": 1,
                        "transition": theme.transitions.create('background'),
                    },
                    "&:hover": {
                        "& .imageBackdrop": {
                            "background": theme.palette.primary.main + "99", // 60% transparency
                            "transition": theme.transitions.create('background'),
                            // "transition": "background 0.4s cubic-bezier(.17,.67,.83,.67)",
                            // "opacity": 0.15,
                        },
                    },
                },
            },
        },
    }),
}