import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import ComponentLayout from '@myaccount/ComponentLayout';
import SwitchBoxType from '../boxType/switchBoxType';
import Grid from '@mui/material/Grid';

import defaults from './defaults';

const IconBox = withSubtheme((props) => {
    const {
        className,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        containerProps,
        itemProps,
        items,
        itemTitleProp,
        itemSubtitleProp,
        itemLinkLabelProp,
    } = props;

    return(
        <ComponentLayout
            className={className}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <Grid container {...containerProps}>
                {items.map((item, i) => (
                    <Grid {...itemProps} key={i}>
                        <SwitchBoxType
                            item={item}
                            itemTitleProp={itemTitleProp}
                            itemSubtitleProp={itemSubtitleProp}
                            itemLinkLabelProp={itemLinkLabelProp}
                        />
                    </Grid>
                ))}
            </Grid>
        </ComponentLayout>
    )

}, "iconBox", defaults)

export default IconBox
