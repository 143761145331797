export default {
    "props": {
        "variant": "default", // or "withCircle" for text display with icons
        "progressComponent": {
            "variant": "determinate",
        },
        "progressValueTypography": {
            "variant": "h3",
        },
        "pendingHeading": {
            "variant": "caption",
            "component": "p",
        },
        "pendingText": {
            "variant": "bodySmall",
        },
        "pendingIconFinished": {
            "variant": "bodySmall",
        },
        "pendingIconPending": {
            "color": "disabled",
        },
        "_data": {
            "items": [
                {
                    "id": "action1",
                    "title": "Mortgage",
                    "status": "Finished",
                },
                {
                    "id": "action2",
                    "title": "Additional Survey",
                    "status": "Finished",
                },
                {
                    "id": "action3",
                    "title": "Additional Survey Carried Out",
                    "status": "Pending",
                },
                {
                    "id": "action4",
                    "title": "Purchaser Exchanged",
                    "status": "Pending",
                },
                {
                    "id": "action5",
                    "title": "Mortgage",
                    "status": "Finished",
                },
                {
                    "id": "action6",
                    "title": "Additional Survey",
                    "status": "Finished",
                },
                {
                    "id": "action7",
                    "title": "Additional Survey Carried Out",
                    "status": "Pending",
                },
                {
                    "id": "action8",
                    "title": "Purchaser Exchanged",
                    "status": "Pending",
                }
            ],
            "percentage": 60
        }
    },
    "sx": (theme) => ({
        "& .progressContainer": {
            "width": "100%",
        },
        "& .progressComponent-root": {
            "height": 5,
            "borderRadius": 2,
        },
        "& .progressComponent-colorPrimary": {
            "backgroundColor": "#BDBDBD",
        },
        "& .progressComponent-bar": {
            "borderRadius": 2,
            "backgroundColor": theme.palette.primary.main,
        },
        "& .progressValueTypography": {
            "color": theme.palette.primary.dark,
            "marginBottom": theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
                "marginBottom": theme.spacing(1.8),
            },
        },
        "& .pendingHeading": {
            "color": theme.palette.grey.grey2,
            "fontWeight": theme.typography.fontWeightBold,
            "marginBottom": theme.spacing(1.25),
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.bodyMedium,
                "lineHeight": theme.lineHeightBLarge,
                "marginBottom": theme.spacing(2),
            },
        },
        "& .pendingTextTitle": {
            "color": theme.palette.primary.dark,
            "fontWeight": theme.typography.fontWeightBold,
            "marginRight": 5,
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.bodyLarge,
                "lineHeight": theme.lineHeightBLarge,
            },
        },
        "& .pendingTextStatus": {
            "color": theme.palette.primary.dark,
            [theme.breakpoints.up('sm')]: {
                "fontSize": theme.bodyLarge,
                "lineHeight": theme.lineHeightBLarge,
            },
        },
        "& .pendingIconFinished": {
            "color": "#8ABB5A",
            "marginRight": theme.spacing(2),
        },
        "& .pendingIconPending": {
            "marginRight": theme.spacing(2),
        },
        "& .progressValueContainer": (theme) => ({
            "display": "flex",
            "flexDirection": "column",
            "marginBottom": theme.spacing(3),
            [theme.breakpoints.up('sm')]: {
                "marginBottom": theme.spacing(5),
            },
        }),
        "& .progressValueContainerProgress": {
            "order": 1,
            "width": "100%",
            "mr": 1,
        },
        "& .progressValueContainerNumber": {
            "order": 0,
        },
        "& .pendingContainer": (theme) => ({
            "display": "flex",
            "flexDirection": "row",
            "marginBottom": theme.spacing(1),
            "&:last-child": {
                "marginBottom": 0,
            }
        }),
        "& .pendingCircleStyle": {
            "display": "flex",
            "flexDirection": "row",
            "alignItems": "center",
            // "paddingBottom": theme.spacing(1),
        },
    }),
}
