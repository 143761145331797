export default {
    "props": {
        "maxDialogW": "sm",
        "fabProps": {
            "color": "primary",
            "variant": "extended",
        },
        "showBoxContainer": true,
        "containerTitle": "Create a Search",
        "containerText": "Set up your search criteria to ensure you receive notifications of the most suitable property as soon as they come onto the market.",
        "defaultArea": "",
        "defaultSearchType": "",
        "boxContainerProps": {
            "item": true,
            "spacing": 0,
        },
        "boxContainerImageProps": {
            "item": true,
            "xs": 12,
            "md": 1,
        },
        "boxContainerContentProps": {
            "item": true,
            "xs": 12,
            "md": 8,
        },
        "boxContainerBtnContainerProps": {
            "item": true,
            "xs": 12,
            "md": 3,
        },
        "boxContainerBtnProps": {
            "variant": "contained",
            "color": "primary",
        },
        "boxContainerTitleProps": {
            "variant": "h5",
        },
        "boxContainerTextProps": {
            "variant": "span",
        },
    },
    "sx": (theme) => ({
        "width": "100%",
        "marginBottom": theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            "width": "auto",
            "minWidth": "300px",
        },
        "& .box-container": {
            "display": "flex",
            "flexWrap": "wrap",
            "alignItems": "center",
            "border": "1px solid #DDDDDD",
            "borderRadius": "4px",
            "padding": theme.spacing(5),
            "& .box-image": {
                "background": theme.palette.grey.grey3,
                "& svg": {
                    "fontSize": "2rem"
                }
            },
        },
        "& .box-image-container": {
            "display": "none",
            [theme.breakpoints.up('lg')]: {
                "display": "block"
            },
        },
        "& .box-image-container, .box-btn-container, .box-content": {
            [theme.breakpoints.down('lg')]: {
                "flexBasis": "100%",
                "maxWidth": "100%",
            },
        },
        "& .box-content": {
            [theme.breakpoints.down('lg')]: {
                "padding": "10px 0",
                "flexBasis": "100%",
                "maxWidth": "100%",
            },
        },
        "& .box-btn-container": {
            "textAlign": "left",
            "paddingTop": theme.spacing(1),
            [theme.breakpoints.up('lg')]: {
                "textAlign": "right",
                "paddingTop": theme.spacing(0),
            }
        },
        "& .box-container-title": {
            "fontFamily": theme.primaryFontFamily,
            "fontWeight": theme.typography.fontWeightBold,
            "fontSize": theme.bodyMediumSize,
            "lineHeight": theme.h5LineH,
            "color": theme.palette.grey.grey6,
        },
        "& .box-container-text": {
            "fontFamily": theme.secondaryFontFamily,
            "fontSize": theme.bodyMedium,
            "lineHeight": theme.lineHeightBMedium,
            "fontWeight": "normal",
            "color": theme.palette.grey.grey2,
        }
    }),
}
