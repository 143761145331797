export default {
    "props": {
        "initialStep": "stepOne",
        "initialFetch": false,
        "initialFetchProps": {},
        "customApiCall": {},
        "onlyModal": false,
        "openDefault": false,
        "valuationForm": {
            "stepOne": {
                nextStep: "StepTwo",
                formParams: {
                    form_name:'viewing_step_one'
                }
            },
            "stepTwo": {
                formParams: {
                    successMessage: "Valuation submitted Successfully",
                    cancelled: true,
                    form_name: 'valuation'
                }
            }
        },
        "cardModuleProps": {
            "elevation": 2,
        },
        "cardModuleTitleProps": {
            // "variant": "h5"
        },
        "cardModuleContentProps": {
            "component": "p",
            "variant": "bodyMedium"
        },
        "cardModuleButtonProps": {
            "color": "primary",
            "variant": "contained",
        },
        "actionCardDialogChildrenStyles": {
            "actionChildrenTitleProps": {
                "component": "h6",
                "variant": "bodyMedium",
            }
        },
    },
    "sx": (theme) => ({
        "& .actionCardDialogChildren-subTitle": {
            "marginTop": theme.spacing(0.5),
        },
        "& .simpleModule-cardModule": {
            "borderRadius": theme.moduleBorderRadius,
        },
        "& .simpleModule-cardModule-media": {
            "height": 140,
        },
        "& .simpleModule-cardContent": {
            "padding": theme.modulePadding,
        },
        "& .simpleModule-cardActions": {
            "padding": theme.modulePadding,
            "paddingTop": "0!important",
        },
        "& .simpleModule-cardModuleTitle": {
            "marginBottom": theme.spacing(1),
        },
        "& .simpleModule-cardModuleContent": {
            "color": "#6A6A6A",
        },
        "& .simpleModule-cardModuleButton": {
            "width": "100%",
        },
    }),
}
