import React from 'react';
import ImageBox from "@myaccount/modules/imageBox";
import IconBox from "@myaccount/modules/iconBox";
import VideoText from "@myaccount/modules/videoText";
import GridContainer from "@myaccount/modules/grid";
import BgIcon from "@myaccount/modules/bgIcon";

const LoopModules = ({modules}) => {
    return(
        <>
            {modules.map((module, i) => {
                return(
                    Object.entries(module).map(([key, value]) => {
                        switch (key) {
                            case "static2column":
                                return <GridContainer {...value} key={i} />
                            case "imageBox":
                                return <ImageBox {...value} key={i} />
                            case "iconBox":
                                return <IconBox {...value} key={i} />
                            case "videoText":
                                return <VideoText {...value} key={i} />
                            case "bgIcon":
                                return <BgIcon {...value} key={i} />
                            default:
                                break;
                        }
                    })
                )
            })}
        </>
    )
}

const ModuleRender = (props) => {
    const {
        staticModules
    } = props

    return(
        <LoopModules modules={staticModules} />
    )
}
export default ModuleRender
