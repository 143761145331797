import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import PropertyGrid from '@components/PropertyGrid';
import ComponentLayout from '@myaccount/ComponentLayout';

const SingleProperty = withSubtheme(({theme, properties, title, moreLabel, moreLinkUrl, titleLinkMoreTooltipProps, ...props}) => {

    return(
        <ComponentLayout
            theme={theme}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <PropertyGrid
                theme={theme}
                properties={properties}
                {...props}
            />
        </ComponentLayout>
    )
}, 'singleProperty')

export default React.memo(SingleProperty)
