export default {
    "props": {
        "showBoxContainer": true,
        "containerTitle": "Save Property to the dashboard",
        "containerText": "Save property you like here. You can then return to it or share it at anytime..",
        "linkLabel": "Search & Save",
        "boxContainerProps": {
            "item": true,
            "spacing": 0,
        },
        "boxContainerImageProps": {
            "item": true,
            "xs": 12,
            "md": 1,
        },
        "boxContainerContentProps": {
            "item": true,
            "xs": 12,
            "md": 8,
        },
        "boxContainerBtnContainerProps": {
            "item": true,
            "xs": 12,
            "md": 3,
        },
        "boxContainerBtnProps": {
            "variant": "contained",
            "color": "primary",
        },
        "boxContainerTitleProps": {
            "variant": "h5",
        },
        "boxContainerTextProps": {
            "variant": "span",
        },
    },
    "sx": (theme) => ({
        "& .box-container": {
            "display": "flex",
            "flexWrap": "wrap",
            "alignItems": "center",
            "border": "1px solid #DDDDDD",
            "borderRadius": "4px",
            "padding": theme.spacing(5),
            "& .box-image": {
                "background": theme.palette.grey.grey3,
                "& svg": {
                    "fontSize": "2rem",
                    "color": theme.palette.primary.icon
                }
            },
        },
        "& .box-image-container": {
            "display": "none",
            [theme.breakpoints.up('lg')]: {
                "display": "block"
            },
        },
        "& .box-image-container, .box-btn-container, .box-content": {
            [theme.breakpoints.down('lg')]: {
                "flexBasis": "100%",
                "maxWidth": "100%",
            },
        },
        "& .box-content": {
            [theme.breakpoints.down('lg')]: {
                "padding-bottom": "10px",
                "flexBasis": "100%",
                "maxWidth": "100%",
            },
            [theme.breakpoints.up('lg')]: {
                "paddingLeft": "10px",
            },
        },
        "& .box-btn-container": {
            "textAlign": "left",
            "paddingTop": theme.spacing(1),
            [theme.breakpoints.up('lg')]: {
                "textAlign": "right",
                "paddingTop": theme.spacing(0),
            }
        },
        "& .box-container-title": {
            "fontFamily": theme.primaryFontFamily,
            "fontWeight": theme.typography.fontWeightBold,
            "fontSize": theme.bodyMediumSize,
            "lineHeight": theme.h5LineH,
            "marginBottom": "5px",
            "color": theme.palette.primary.main,
            [theme.breakpoints.up('xl')]: {
                "marginBottom": "10px",
            },
        },
        "& .box-container-text": {
            "fontFamily": theme.saveSearchTextFontFamily || theme.secondaryFontFamily,
            "fontSize": theme.bodyMedium,
            "lineHeight": theme.lineHeightBMedium,
            "fontWeight": "normal",

            [theme.breakpoints.down('md')]: {
                "marginBottom": "5px",
                "display": "block"
            },
            "color": theme.palette.grey.grey2
        }
    }),
}
