export default {
    "props": {
        "dropdownActionsIcon": {
            // "fontSize": "small",
        },
    },
    "sx": (theme) => ({
        "& .globalDropdownActions-dropdownActions": {
            "fontSize": theme.bodyMedium,
        },
        "& .globalDropdownActions-dropdownActionsIcon": {
            "minWidth": "34px",
            "color": theme.palette.text.primary,
        },
    }),
}