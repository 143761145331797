import * as React from 'react';
import PropTypes from 'prop-types';
import { withSubtheme } from '@starberryComponentsMui';

import ComponentLayout from '@myaccount/ComponentLayout';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import defaults from './defaults';

function LinearProgressWithLabel(props) {
    const {
        progressValueTypography,
        progressComponent,
        value
    } = props;

    return (
        <div className="progressValueContainer">
            <div className="progressValueContainerProgress">
                <LinearProgress
                    classes={{
                        root: "progressComponent-root",
                        colorPrimary: "progressComponent-colorPrimary",
                        bar: "progressComponent-bar"
                    }}
                    value={value}
                    {...progressComponent}
                />
            </div>
            <div className="progressValueContainerNumber">
                <Typography {...progressValueTypography} classes={{root: "progressValueTypography"}}>{`${Math.round(
                    value,
                )}%`}</Typography>
            </div>
        </div>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

const PendingItems = withSubtheme((props) => {
    const {
        className,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        heading,
        variant,
        progressComponent,
        progressValueTypography,
        pendingHeading,
        pendingText,
        pendingIconFinished,
        pendingIconPending,
        _data,
        data
    } = props;

    const mainData = data ? data : _data

    // if no data render with empty
    if (!mainData?.items) {
        return <></>
    }
    // if no data render with empty
    if (!mainData?.percentage) {
        return <></>
    }

    // const [progress, setProgress] = React.useState(10);

    // React.useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    //     }, 800);
    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);

    let DisplayVariant = null;
    switch (variant) {
        case "withCircle":
            DisplayVariant = props => (
                <div className="pendingCircleStyle">
                    {props.pending.status === "completed"
                        ? <CheckCircleIcon {...pendingIconFinished} classes={{root: "pendingIconFinished"}} />
                        : <CancelIcon {...pendingIconPending} classes={{root: "pendingIconPending"}} />
                    }
                    <Typography {...pendingText} classes={{root: "pendingTextTitle"}}>{props.pending.title}</Typography>
                </div>
            )
            break;
        default:
            DisplayVariant = props => (
                <>
                    <Typography {...pendingText} classes={{root: "pendingTextTitle"}}>{props.pending.title}{` `}:</Typography>
                    <Typography {...pendingText} classes={{root: "pendingTextStatus"}}>{props.pending.status}</Typography>
                </>
            )
            break;
    }

    return(
        <ComponentLayout
            className={className}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <div className="progressContainer">
                <LinearProgressWithLabel
                    value={mainData.percentage}
                    progressValueTypography={progressValueTypography}
                    progressComponent={progressComponent}
                />
            </div>
            <Typography {...pendingHeading} classes={{root: "pendingHeading"}}>{heading ? heading : "Outstanding Items"}</Typography>
            {mainData?.items && mainData.items.map(pending => (
                <>
                    {pending.title && pending.status &&
                        <div key={pending.id} className="pendingContainer">
                            <DisplayVariant pending={pending} />
                        </div>
                    }
                </>
            ))}
        </ComponentLayout>
    )
}, 'pendingItems', defaults)

export default PendingItems;
