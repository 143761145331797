import React, { useEffect } from 'react';
// import NoActions from "@myaccount/progress/NoActions";
import Alert from "@myaccount/alert";
import { usePropertyActionState } from "@myaccountServices"
// import { withSubtheme } from '@starberryComponentsMui';
import PropertyResultComponent from '@myaccount/property/results';
import Grid from "@mui/material/Grid"
import DetailedPropertyCardSkeleton
    from "../../../../StarberryComponentsMui/components/Skeleton/DetailedPropertyCardSkeleton"
import range from 'lodash/range';
// import defaults from './defaults';

const ArchivedProperties = (props) => {
    const { listType, reloadDataFun } = props
    const { state, services } = usePropertyActionState();

    React.useEffect(() => {
        services.getArchivedProperties()
    }, [])


    const noRecord = (!state.loading && !state?.archivedProperties);

    if (state.loading) {
        return (
            <Grid container spacing={4}>
                {range(0, 3).map(index => (
                    <Grid key={index} item xs={12} md={6} lg={4}>
                        <DetailedPropertyCardSkeleton />
                    </Grid>
                ))}
            </Grid>
        )
    }

    if (state.error) {
        return <Alert type={`alert`} message={state.message} />
    }

    return (
        <PropertyResultComponent properties={state?.archivedProperties || []} listType={listType} reloadDataFun={reloadDataFun} />
    )
}

export default ArchivedProperties;
