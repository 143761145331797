import React from "react"
import isEmpty from "lodash/isEmpty"
import Tabs from "../tabs"
import Alert from "@myaccount/alert";
import MyPropertiesResultsListSkeleton from "./my-properties-results-list-skeleton"

const MyPropertyResultsList = ({ theme, data, currentTab, tabItems, loading, message, myPropertiesData, error, }) => {
    if (loading && isEmpty(myPropertiesData)) {
        return <MyPropertiesResultsListSkeleton />
    }

    if (error) {
        return <Alert type="alert" message={message} />
    }

    return (
        <Tabs
            theme={theme}
            data={data}
            manageValue={currentTab}
            tabItems={tabItems}
            addUrl={true}
        />
    )
};

export default MyPropertyResultsList;
