import React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import SimpleModule from '@myaccount/simpleModule';
import { MYACCOUNT_LIGHT } from "@myaccountUrls";

const ValuationModule = withSubtheme((props) => {
    return (
        <>
            { !MYACCOUNT_LIGHT &&
                <SimpleModule
                    moduleTitle="Value my property"
                    content="Book a valuation from one of our property experts."
                    btnLabel="Valuation"
                    {...props}
                />
            }
        </>
    )
}, "simpleValuationModule")

export default ValuationModule;
