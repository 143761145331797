export default {
    "props": {
        "containerProps": {
            "spacing": 0.9,
        },
        "itemProps": {
            "item": true,
            "xs": 12,
            "lg": 4,
        },
        "itemTitleProp": {
            "component": "h3",
            "variant": "h5",
            "color": "#fff",
        },
        "itemSubtitleProp": {
            "variant": "body1",
            "color": "#fff",
        },
        "itemLinkLabelProp": {

        },
    },
    "sx": (theme) => ({
        "& .imageBox-link": {
            "position": "relative",
            "display": "flex",
            "flexDirection": "column",
            "alignItems": "center",
            "justifyContent": "center",
            "backgroundRepeat": "no-repeat",
            "backgroundSize": "cover",
            "backgroundPosition": "center",
            "textDecoration": "none",
            "height": "210px",
            "marginBottom": theme.spacing(2),
            [theme.breakpoints.up('lg')]: {
                "marginBottom": 0,
                "marginRight": theme.spacing(2),
            },
            [theme.breakpoints.up('xl')]: {
                "marginBottom": 0,
                "marginRight": theme.spacing(6),
            },
            "&:after": {
                "content": "''",
                "position": "absolute",
                "top": 0,
                "left": 0,
                "width": "100%",
                "height": "100%",
                "background": "linear-gradient(transparent, rgba(0,0,0,0.7))",
                "opacity": 1,
                "zIndex": 2,
            },
            "& .imageBox-content": {
                "position": "relative",
                "textAlign": "center",
                "padding": theme.spacing(3),
                "zIndex": 5,
                "& .imageBox-title": {
                    [theme.breakpoints.up('lg')]: {
                        "opacity": 1,
                        "fontSize": "1.6rem",
                        "visibility": "visible",
                        "transform": "translateY(5px)",
                        "transition": "all 0.6s ease-in",
                    },
                },
                "& .imageBox-subtitle": {
                    [theme.breakpoints.up('lg')]: {
                        "opacity": 0,
                        "visibility": "hidden",
                        "height": 0,
                        "transform": "translateY(20px)",
                        "transition": "all 0.6s linear",
                    },
                },
            },
            "&:hover": {
                "&:after": {
                    "background": theme.palette.primary.main + "99", // 60% transparency
                    "transition": "background 0.8s linear",
                },
                "& .imageBox-content": {
                    "& .imageBox-title": {
                        [theme.breakpoints.up('lg')]: {
                            "opacity": 1,
                            "height": "auto",
                            "transform": "translateY(-5px)",
                            "transition": "all 0.6s linear, opacity 0.2s linear",
                        },
                    },
                    "& .imageBox-subtitle": {
                        [theme.breakpoints.up('lg')]: {
                            "opacity": 1,
                            "visibility": "visible",
                            "height": "auto",
                            "transform": "translateY(0)",
                            "transition": "all 0.6s linear, opacity 0.2s linear",
                        },
                    },
                },
            },
        },
    }),
}