import React from "react";
import { pickBy, identity } from "lodash";
import { withSubtheme } from '@starberryComponentsMui';
import GlobalForm from "@myaccount/forms/";
import { collectFormValues, validateFormFields } from "@myaccount/forms/utils";
import { useUserObjectState } from "@myaccountServices";
import savedSearchFields from "@myaccount/forms/Forms/createSavedSearch";
import propertyPreferenceFields from "@myaccount/forms/Forms/propertyPreference";
import { savedSearchParams } from '@myaccount/pages/properties/functions';
import { trackSaveSearch } from "@myaccount/helper/eventTracking";

import defaults from "@myaccount/property/components/reportFaultBtn/dialogChildrenDefaults";

const DialogChildren = withSubtheme((props) => {

    const { className } = props
    const { services } = useUserObjectState();
    const data = props?.data;

    // re organise the fields values with the new values
    let customFields = [...savedSearchFields];
    if (data?.is_crm || props?.searchParams?.is_crm) {
        customFields = [...propertyPreferenceFields];
    }
    const [formFields, setFormFields ] = React.useState([...customFields]);

    const ref = React.useRef(null)
    const formStateType = data?.params || {}
    const messageDialog = { message: "", severity: "" }
    const formData = { submitLabel: "Submit" }
    const onSubmit = event => {
        event.preventDefault()

        const formWithValue = validateFormFields(ref, customFields)

        if (ref.current.checkValidity() !== false) {
            let values = collectFormValues(ref, {})
            let areas = values?.areas || props?.defaultArea || '';
            values.areas = areas.toLowerCase();
            let search_type = values?.search_type || props?.defaultSearchType;
            values.search_type = search_type;

            if(values?.building && values?.building ==='any') {
                values.building = '';
            }

            // remove empty values
            values = savedSearchParams(values)
            
            if (props?.searchParams?.id) {
                services.updateUserobject({params:values, type:'search', state:1, ...(props?.searchParams || {})})
                trackSaveSearch({
                    eventLabel: "Update"
                })
            } else {
                services.createUserobject({params:values, type:'search', state:1, ...(props?.searchParams || {})})
                trackSaveSearch({
                    eventLabel: "Create"
                })
            }
        } else {
            // @todo
            setFormFields(formWithValue);
        }
    }

    return(
        <div className={className} style={{"position": "relative"}}>
            <GlobalForm
                formFields={formFields}
                handleSubmit={onSubmit}
                formStateType={formStateType}
                messageDialog={messageDialog}
                data={formData}
                ref={ref}
            />
        </div>
    )
}, 'dialogChildren', defaults)

export default DialogChildren
