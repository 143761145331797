import React from 'react';
import { withSubtheme } from '@starberryComponentsMui';
import { isEmpty } from 'lodash';
import { useUserObjectState } from "@myaccountServices";
import SavedPropertyResultComponent from '@myaccount/property/results/savedPropertyResults';
import NoActions from "@myaccount/progress/NoActions";
import Alert from "@myaccount/alert";
import FlexBox from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { LocationOnOutlined } from '@mui/icons-material';
import ComponentLayout from '@myaccount/ComponentLayout';
import { doRedirect } from "@myaccount/utils";
import { trackSaveSearchBtn } from "@myaccount/helper/eventTracking";

import defaults from './defaults';
import Grid from "@mui/material/Grid"
import DetailedPropertyCardSkeleton
    from "../../../../StarberryComponentsMui/components/Skeleton/DetailedPropertyCardSkeleton"
import range from 'lodash/range';

const SavedProperties = withSubtheme((props) => {

    const {
        className,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,

        showBoxContainer,
        noActionMessage,
        boxContainerProps,
        boxContainerImageProps,
        boxContainerContentProps,
        boxContainerTitleProps,
        boxContainerTextProps,
        boxContainerBtnContainerProps,
        boxContainerBtnProps,
        containerTitle,
        containerText,
        linkLabel,
        resultLinkFun,
    } = props;

    let propData, savedPropertiesData = [];
    const { state, services } = useUserObjectState();

    const showFindPropertyLink = typeof resultLinkFun === 'function' ? true : false;

    React.useEffect(() => {
        let params = {type:'property', state: true, related: true};
        if (!isEmpty(props?.relatedQuery)) {
            params['relatedQuery'] = JSON.stringify(props.relatedQuery)
        }
        services.getUserobject(params);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (state?.userObjects?.property) {

        propData = state?.userObjects.property;
        Object.entries(propData).map(([key, value]) => {
            if (!isEmpty(value.data))
                savedPropertiesData.push({...value.data, objectId:value.id});
            return true;
        })

    }

    const noRecord = (!state.loading && !savedPropertiesData.length);

    if (state.loading) {
        return (
            <Grid container spacing={4}>
                {range(0, 3).map(index => (
                    <Grid key={index} item xs={12} md={6} lg={4}>
                        <DetailedPropertyCardSkeleton />
                    </Grid>
                ))}
            </Grid>
        )
    }

    if (state.error) {
        return <Alert type={`alert`} message={state.message} />
    }

    const handleBtnClick = () => {
        let url = resultLinkFun({});
        if (url) {
            trackSaveSearchBtn({
                eventLabel: "Myaccount"
            });
            doRedirect(url);
        }
    }

    return(
        <ComponentLayout
            className={className}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            {noRecord && (
                <NoActions
                    title={showBoxContainer ? '' : "There are no saved properties"}
                    message={noActionMessage ? noActionMessage : "Search the property and add it in your favorite list"}
                    icon={showBoxContainer ? '' : "savedPropertiesIcon"}
                    showBoxContainer={showBoxContainer}
                />
            )}
            {!noRecord && (
                <SavedPropertyResultComponent properties={savedPropertiesData} />
            )}
            {showBoxContainer && (
                <FlexBox classes={{root: "box-container"}} {...boxContainerProps}>
                    <FlexBox {...boxContainerImageProps} classes={{root: "box-image-container"}}>
                        <IconButton classes={{root: "box-image"}}  onClick={handleBtnClick}>
                            <LocationOnOutlined />
                        </IconButton>
                    </FlexBox>
                    <FlexBox classes={{root: "box-content"}} {...boxContainerContentProps}>
                        {containerTitle && <Typography classes={{root: "box-container-title"}} {...boxContainerTitleProps}>{containerTitle}</Typography>}
                        {containerText && <Typography classes={{root: "box-container-text"}}{...boxContainerTextProps}>{containerText}</Typography>}
                    </FlexBox>
                    {showFindPropertyLink && (
                        <FlexBox {...boxContainerBtnContainerProps} classes={{root: "box-btn-container"}}>
                            <Button {...boxContainerBtnProps} onClick={handleBtnClick}>{linkLabel}</Button>
                        </FlexBox>
                    )}
                </FlexBox>
            )}
        </ComponentLayout>
    )
}, 'savedProperties', defaults)

export default SavedProperties
