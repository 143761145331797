export default {
    "props": {
        "containerProps": {
            "spacing": 1,
        },
        "leftColumnProps": {
            "item": true,
            "xs": 12,
            "lg": 7,
        },
        "rightColumnProps": {
            "item": true,
            "xs": 12,
            "lg": 5,
        },
    },
    "sx": {

    },
}