import * as React from 'react';
import { withSubtheme } from '@starberryComponentsMui';

import ComponentLayout from '@myaccount/ComponentLayout';
import Action from './savedSearch';
import FabBtnSaveSearch from '@myaccount/fabBtn/saveSearch/FabBtnSaveSearch';
import { useUserObjectState } from "@myaccountServices"
import { preProcessObjects } from '../utils'
import NoActions from "@myaccount/progress/NoActions";
import Alert from "@myaccount/alert"
import { hasSavedSearch } from '@myaccount/helper';

import defaults from './defaults';
import PropertyPreferenceSkeleton from "./propertyPreferenceSkeleton"

const savedSearches = withSubtheme((props) => {

    const {
        className,
        // children,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        showActionButton = true,
        resultLinkFun,

        cardProps,
        cardActionProps,
        cardActionButtonProps,
        cardContentProps,
        // dropdownActions,
        // ...rest
        _queryParams,
        canAdd,
        noactionTitle,
        noactionMessage,
        labelText,
        modalTitle,
        containerTitle,
        containerText,
        showBoxContainer
    } = props;

    const { state, services } = useUserObjectState();
    const queryParams = _queryParams || {}
    let canAddBtn = (canAdd === undefined ) ? true : canAdd

    React.useEffect(() => {
        services.getUserobject({type:'search', is_crm:false, ...queryParams});
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const data = preProcessObjects(state?.userObjects?.search || [])
    const noRecord = (!state.loading && !data.length);
    if (props?.hideAddMore && !noRecord) {
        canAddBtn = false
    }

    if (state.loading) {
        return <PropertyPreferenceSkeleton />
    }

    if (state.error) {
        return <Alert type={`alert`} message={state.message} />
    }

    return(
        <ComponentLayout
            className={className}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            {noRecord && (
                <NoActions
                    title={noactionTitle || ( showBoxContainer ? '' : "There are no saved searches" )}
                    message={noactionMessage || "Click Add Search and add new saved search"}
                    icon={showBoxContainer ? '' : "savedSearchIcon"}
                    showBoxContainer={showBoxContainer}
                />
            )}
            {canAddBtn && (
                <FabBtnSaveSearch modalTitle={modalTitle || `Create a new search criteria`} searchParams={queryParams} label={labelText} addNew={!hasSavedSearch(data)} containerTitle={containerTitle} containerText={containerText} />
            )}
            {!noRecord && data.map(action =>
                <Action
                    key={action.id}
                    card={cardProps}
                    cardAction={cardActionProps}
                    showActionButton={showActionButton}
                    cardActionButton={cardActionButtonProps}
                    cardContent={cardContentProps}
                    data={action}
                    services={services}
                    resultLinkFun={resultLinkFun}
                />
            )}

        </ComponentLayout>
    )
}, 'savedSearchResults', defaults)

export default savedSearches;
